<!-- list -->
<template>
  <el-main>
    <page-title />
    <div class="tabs-inner">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="平台端" name="tenant">
          <div class="partition-table">
            <el-table
              :data="tenantList"
              style="width: 100%"
              row-key="id"
              default-expand-all
              :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
            >
              <el-table-column prop="functionCode" label="所属编码" min-width="20%" />
              <el-table-column prop="functionName" label="所属模块" min-width="30%" />
              <el-table-column label="状态" min-width="30%">
                <template slot-scope="scope">
                  <span v-if="scope.row.parentFunctionCode">
                    <span v-if="scope.row.status===1">{{ scope.row.status === 1 ? '已启用' : '未启用' }}</span>
                    <span v-else>{{ scope.row.status === 1 ? '已启用' : '未启用' }}</span>
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="操作" min-width="20%">
                <template slot-scope="scope">
                  <a v-if="scope.row.parentFunctionCode && scope.row.status === 0" href="javascript:" class="text-btn" @click="execute(scope.row.id)">执行</a>
                  <a v-if="scope.row.parentFunctionCode && scope.row.status === 1" href="javascript:" class="text-btn danger" @click="stop(scope.row.id)">停止</a>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="核心企业端" name="core">
          <div class="partition-table">
            <el-table
              :data="coreList"
              style="width: 100%"
              row-key="id"
              default-expand-all
              :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
            >
              <el-table-column prop="functionCode" label="所属编码" min-width="20%" />
              <el-table-column prop="functionName" label="所属模块" min-width="30%" />
              <el-table-column label="状态" min-width="30%">
                <template slot-scope="scope">
                  <span v-if="scope.row.parentFunctionCode">
                    <span v-if="scope.row.status===1">{{ scope.row.status === 1 ? '已启用' : '未启用' }}</span>
                    <span v-else>{{ scope.row.status === 1 ? '已启用' : '未启用' }}</span>
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="操作" min-width="20%">
                <template slot-scope="scope">
                  <a v-if="scope.row.parentFunctionCode && scope.row.status === 0" href="javascript:" class="text-btn" @click="execute(scope.row.id)">执行</a>
                  <a v-if="scope.row.parentFunctionCode && scope.row.status === 1" href="javascript:" class="text-btn danger" @click="stop(scope.row.id)">停止</a>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="融资端" name="customer">
          <div class="partition-table">
            <el-table
              :data="customerList"
              style="width: 100%"
              row-key="id"
              default-expand-all
              :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
            >
              <el-table-column prop="functionCode" label="所属编码" min-width="20%" />
              <el-table-column prop="functionName" label="所属模块" min-width="30%" />
              <el-table-column label="状态" min-width="30%">
                <template slot-scope="scope">
                  <span v-if="scope.row.parentFunctionCode">
                    <span v-if="scope.row.status===1">{{ scope.row.status === 1 ? '已启用' : '未启用' }}</span>
                    <span v-else>{{ scope.row.status === 1 ? '已启用' : '未启用' }}</span>
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="操作" min-width="20%">
                <template slot-scope="scope">
                  <a v-if="scope.row.parentFunctionCode && scope.row.status === 0" href="javascript:" class="text-btn" @click="execute(scope.row.id)">执行</a>
                  <a v-if="scope.row.parentFunctionCode && scope.row.status === 1" href="javascript:" class="text-btn danger" @click="stop(scope.row.id)">停止</a>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="资方端" name="capital">
          <div class="partition-table">
            <el-table
              :data="capitalList"
              style="width: 100%"
              row-key="id"
              default-expand-all
              :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
            >
              <el-table-column prop="functionCode" label="所属编码" min-width="20%" />
              <el-table-column prop="functionName" label="所属模块" min-width="30%" />
              <el-table-column label="状态" min-width="30%">
                <template slot-scope="scope">
                  <span v-if="scope.row.parentFunctionCode">
                    <span v-if="scope.row.status===1">{{ scope.row.status === 1 ? '已启用' : '未启用' }}</span>
                    <span v-else>{{ scope.row.status === 1 ? '已启用' : '未启用' }}</span>
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="操作" min-width="20%">
                <template slot-scope="scope">
                  <a v-if="scope.row.parentFunctionCode && scope.row.status === 0" href="javascript:" class="text-btn" @click="execute(scope.row.id)">执行</a>
                  <a v-if="scope.row.parentFunctionCode && scope.row.status === 1" href="javascript:" class="text-btn danger" @click="stop(scope.row.id)">停止</a>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="运营端" name="channel">
          <div class="partition-table">
            <el-table
              :data="channelList"
              style="width: 100%"
              row-key="id"
              default-expand-all
              :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
            >
              <el-table-column prop="functionCode" label="所属编码" min-width="20%" />
              <el-table-column prop="functionName" label="所属模块" min-width="30%" />
              <el-table-column label="状态" min-width="30%">
                <template slot-scope="scope">
                  <span v-if="scope.row.parentFunctionCode">
                    <span v-if="scope.row.status===1">{{ scope.row.status === 1 ? '已启用' : '未启用' }}</span>
                    <span v-else>{{ scope.row.status === 1 ? '已启用' : '未启用' }}</span>
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="操作" min-width="20%">
                <template slot-scope="scope">
                  <a v-if="scope.row.parentFunctionCode && scope.row.status === 0" href="javascript:" class="text-btn" @click="execute(scope.row.id)">执行</a>
                  <a v-if="scope.row.parentFunctionCode && scope.row.status === 1" href="javascript:" class="text-btn danger" @click="stop(scope.row.id)">停止</a>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-main>
</template>
<script>
export default {
  components: {},
  data () {
    return {
      activeName: 'tenant',
      tenantList: [],
      coreList: [],
      customerList: [],
      capitalList: [],
      channelList: [],
      loading: {
        submit: false,
        tab: false
      },
    }
  },
  computed: {
  },
  watch: {},
  created () {
    this.getTenantAllList()
    this.getCoreAllList()
    this.getCustomerAllList()
    this.getCapitalAllList()
    this.getChannelAllList()
  },
  methods: {
    getTenantAllList () {
      this.api.system.cert.listTenant({appKey: 'finance-frame-tenant'}).then(result => {
        this.tenantList = result.data.data || []
      }).finally(() => {
      })
    },
    getCoreAllList () {
      this.api.system.cert.listTenant({appKey: 'finance-frame-core'}).then(result => {
        this.coreList = result.data.data || []
      }).finally(() => {
      })
    },
    getCustomerAllList () {
      this.api.system.cert.listTenant({appKey: 'finance-frame-customer'}).then(result => {
        this.customerList = result.data.data || []
      }).finally(() => {
      })
    },
    getCapitalAllList () {
      this.loading.tab = true
      this.api.system.cert.listTenant({appKey: 'finance-frame-capital'}).then(result => {
        this.capitalList = result.data.data || []
      }).finally(() => {
      })
    },
    getChannelAllList () {
      this.loading.tab = true
      this.api.system.cert.listTenant({appKey: 'finance-frame-channel'}).then(result => {
        this.channelList = result.data.data || []
      }).finally(() => {
      })
    },
    execute (val) {
      this.api.system.cert.execute(val).then(result => {
        if (result.data.success === true) {
          this.$message.success('操作成功')
          if (this.activeName === 'core') {
            this.getCoreAllList()
          }
          if (this.activeName === 'tenant') {
            this.getTenantAllList()
          }
          if (this.activeName === 'capital') {
            this.getCapitalAllList()
          }
          if (this.activeName === 'customer') {
            this.getCustomerAllList()
          }
          if (this.activeName === 'channel') {
            this.getChannelAllList()
          }
        } else {
          this.$message.error(result.data.message)
        }
      }).catch(e => {
        this.loading.submit = false
      })
    },
    stop (val) {
      this.api.system.cert.stop(val).then(result => {
        if (result.data.success === true) {
          this.$message.success('操作成功')
          if (this.activeName === 'core') {
            this.getCoreAllList()
          }
          if (this.activeName === 'tenant') {
            this.getTenantAllList()
          }
          if (this.activeName === 'capital') {
            this.getCapitalAllList()
          }
          if (this.activeName === 'customer') {
            this.getCustomerAllList()
          }
          if (this.activeName === 'channel') {
            this.getChannelAllList()
          }
        } else {
          this.$message.error(result.data.message)
        }
      }).catch(e => {
        this.loading.submit = false
      })
    },
  }
}
</script>
<style></style>
